/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import MaUTable from '@material-ui/core/Table';

import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import TableContainer from '@material-ui/core/TableContainer';
import {TableHead, TableBody} from 'components/Table';
import styles from './DashboardTable.module.scss';

export default function DashboardTable({
  columns,
  data,
  fetchData,
  isLoading,
  isError,
  disableFilters,
  // If you want customize 'No Data'
  // when data is empty
  noDataLabel,
  tableToolbar: TableToolbar,
  pagination,
  // Get Props from Header, Column, Cell and Row
  getHeaderProps,
  getColumnProps,
  getRowProps,
  getCellProps,
}) {
  const {
    disable: disablePagination,
    perPageSize,
    pageSizeOptions,
    ...otherProps
  } = {
    ...DashboardTable.defaultProps.pagination,
    ...pagination,
  };

  // Use the useTable hook to create your table configuration
  const instance = useTable(
    {
      columns,
      data,
      // Get the state from the instance
      initialState: {pageSize: perPageSize}, // Pass our hoisted table state
      disableSortRemove: true,
      disableMultiSort: true,
      disableSortBy: data.length === 0 || isError,
      disableFilters: disableFilters || isError,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    // rows is All Data
    rows, // or use page
    page,
    // 'page' is Data Perpage,
    // but if your data is slice from server
    // you can use one of them (rows or page)

    canPreviousPage,
    canNextPage,
    pageOptions: pageIndexOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex, pageSize, globalFilter}, // Pass our hoisted table state
  } = instance;

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    setPageSize(perPageSize);
  }, [perPageSize, setPageSize]);

  const tableHeadProps = {
    headerGroups,
    getColumnProps,
    getHeaderProps,
  };

  const tableBodyProps = {
    rows: disablePagination ? rows : page,
    prepareRow,
    getTableBodyProps,
    headerGroups,
    pageSize,
    pageIndex,
    isLoading,
    noDataLabel,
    getRowProps,
    getColumnProps,
    getCellProps,
  };

  const tablePaginationProps = {
    rows: disablePagination ? rows : page,
    pageSize,
    setPageSize,
    canPreviousPage,
    previousPage,
    gotoPage,
    nextPage,
    canNextPage,
    pageIndexOptions,
    pageIndex,
    totalItems: rows.length,
    pageSizeOptions: [perPageSize, ...pageSizeOptions],
    disable: disablePagination,
    ...otherProps,
  };

  return (
    <div className={styles['table-section']}>
      {/* <TableToolbar
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      /> */}
      <TableContainer classes={{root: styles['table-section-container']}}>
        <MaUTable
          {...getTableProps()}
          aria-labelledby="table"
          size="small"
          stickyHeader>
          <TableHead {...tableHeadProps} />
          <TableBody {...tableBodyProps} />
        </MaUTable>
      </TableContainer>
      {/* <TablePagination {...tablePaginationProps} /> */}
    </div>
  );
}

DashboardTable.defaultProps = {
  data: [],
  fetchData: () => {},
  isLoading: false,
  isError: false,
  disableFilters: false,
  noDataLabel: 'No Data',
  tableToolbar: () => <div />,
  getHeaderProps: () => ({}),
  getColumnProps: () => ({}),
  getRowProps: () => ({}),
  getCellProps: () => ({}),
  pagination: {
    disable: false,
    disablePageSizeOptions: false,
    disablePageNavigation: false,
    perPageSize: 10,
    pageSizeOptions: [50, 100],
    pageRangeDisplayed: 3,
  },
};

DashboardTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  disableFilters: PropTypes.bool,
  noDataLabel: PropTypes.string,
  // Toolbar or Controller for Table, like GlobalFilter
  tableToolbar: PropTypes.func,
  getHeaderProps: PropTypes.func,
  getColumnProps: PropTypes.func,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  // Props for Pagination
  pagination: PropTypes.shape({
    disable: PropTypes.bool,
    disablePageSizeOptions: PropTypes.bool,
    disablePageNavigation: PropTypes.bool,
    perPageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    pageRangeDisplayed: PropTypes.number,
  }),
};
