import React from 'react';

const SearchIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    {...props}>
    <rect width="20.741" height="20" fill="#FED530" rx="4" />
    <path
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="1.499"
      d="M12.748 11.928a3.995 3.995 0 00.988-2.88 4.025 4.025 0 00-1.341-2.743 4.283 4.283 0 00-2.926-1.084 4.274 4.274 0 00-2.887 1.175A4.016 4.016 0 005.333 9.18a4.002 4.002 0 001.083 2.847A4.258 4.258 0 009.23 13.36a4.297 4.297 0 002.985-.921l2.406 2.36a.237.237 0 00.328 0l.203-.194a.222.222 0 000-.317l-2.403-2.36z"
    />
  </svg>
);

export default SearchIcon;
