/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {ArrowUpwardRounded, ArrowDownwardRounded} from '@material-ui/icons';

import styles from './TableHead.module.scss';

export default function TableHead({headerGroups, ...rest}) {
  const {getColumnProps, getHeaderProps} = rest;
  return (
    <MuiTableHead className={styles['table-head']}>
      {headerGroups.map((headerGroup, i) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          classes={{head: styles['table-head-row']}}>
          {headerGroup.headers.map(column => (
            <TableCell
              // Return an array of prop objects and react-table will merge them appropriately
              {...column.getHeaderProps([
                {
                  className: classNames(
                    styles['table-head-cell'],
                    column.className
                  ),
                  style: column.style,
                },
                getColumnProps(column),
                getHeaderProps(column),
              ])}
              // padding="none"
              key={column.id}
              align="left"
              sortDirection={column.isSortedDesc ? 'desc' : 'asc'}>
              {column.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MuiTableHead>
  );
}

TableHead.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};
