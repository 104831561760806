/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiTableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// import Skeleton from '@material-ui/lab/Skeleton';
import styles from './TableBody.module.scss';

export default function TableBody({
  rows,
  prepareRow,
  getTableBodyProps,
  headerGroups,
  pageSize,
  pageIndex,
  ...rest
}) {
  const {isLoading, noDataLabel, getRowProps, getColumnProps, getCellProps} =
    rest;

  const emptyrows = pageSize - Math.min(pageSize, rows.length);

  const renderLoadingRow = React.useCallback(
    () =>
      [...Array(Math.max(emptyrows, pageSize))].map((_, i) => (
        <TableRow className={styles['table-body-row']} key={i}>
          {[...Array(Number(headerGroups[0].headers.length))].map((__, j) => (
            <TableCell key={j} className={styles['table-body-cell']}>
              {/* <Skeleton variant="rect" /> */}
              Loading
            </TableCell>
          ))}
        </TableRow>
      )),
    [emptyrows, headerGroups, pageSize]
  );

  const renderEmptyRow = React.useCallback(
    () => (
      <TableRow className={styles['table-body-row-empty']}>
        <TableCell
          style={{
            height: 32 * emptyrows,
          }}
          className={classNames(styles['table-body-cell'], {
            [styles['cell-no-data']]: rows.length === 0,
          })}
          colSpan={Number(headerGroups[0].headers.length)}>
          {rows.length === 0 && noDataLabel}
        </TableCell>
      </TableRow>
    ),
    [emptyrows, headerGroups, noDataLabel, rows.length]
  );
  const renderDataRow = React.useCallback(
    () => (
      <>
        {rows.map(
          (row, i) =>
            prepareRow(row) || (
              <TableRow
                {...row.getRowProps(getRowProps(row))}
                className={styles['table-body-row']}
                classes={{
                  hover: styles['table-body-row-hover'],
                }}
                key={i}
                hover>
                {row.cells.map((cell, j) => (
                  <TableCell
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: classNames(
                          styles['table-body-cell'],
                          cell.column.className
                        ),
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])}
                    key={j}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
        )}
      </>
    ),
    [getCellProps, getColumnProps, getRowProps, prepareRow, rows]
  );

  const renderRow = React.useCallback(() => {
    if (isLoading) {
      return renderLoadingRow();
    }
    if (rows.length === 0) {
      return renderEmptyRow();
    }
    return renderDataRow();
  }, [isLoading, renderDataRow, renderEmptyRow, renderLoadingRow, rows.length]);

  return (
    <>
      <MuiTableBody {...getTableBodyProps()} className={styles['table-body']}>
        {renderRow()}
      </MuiTableBody>
    </>
  );
}

TableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
};
