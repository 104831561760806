import React from 'react';
import PropTypes from 'prop-types';
import {InputBase, Paper} from '@material-ui/core';
import {SearchIcon} from 'components/Icons';
import styles from './SearchField.module.scss';

const SearchField = ({placeholder, className, ...rest}) => (
  <Paper className={className} classes={{root: styles['search-field']}}>
    <InputBase
      fullWidth
      placeholder={placeholder}
      inputProps={{'aria-label': `${placeholder}`, ...rest.inputProps}}
      classes={{
        root: styles['search-input'],
      }}
    />
    <SearchIcon className={styles['search-icon']} />
  </Paper>
);

SearchField.defaultProps = {
  className: '',
  placeholder: 'Search... ',
};

SearchField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchField;
