import React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {NavigateNextRounded} from '@material-ui/icons';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = (props, ...rest) => (
  <MuiBreadcrumbs
    classes={{root: styles['breadcrumbs-container']}}
    separator={
      <NavigateNextRounded
        fontSize="small"
        classes={{
          fontSizeSmall: styles.separator,
        }}
      />
    }
    aria-label="breadcrumb"
    {...rest}>
    <Link href="/" classes={{root: styles.link}}>
      Home
    </Link>
    <Link href="/" classes={{root: styles.link}}>
      Setup
    </Link>
    <Typography classes={{root: styles.location}}>Unit Model</Typography>
  </MuiBreadcrumbs>
);

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
