import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import SearchField from 'components/SearchField';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  dataChartMsr,
  dataChartPcar,
  dataSite,
  dataUnit,
  dataAbnormality,
  dataOilAnalysis,
  dataChartHealth,
} from 'dummy';
import DashboardTable from 'components/DashboardTable/DashboardTable';
import classNames from 'classnames';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const [activeFilter, setActiveFilter] = React.useState(30);

  const header = [
    {
      id: 'Site',
      label1: 'Total',
      value1: 51,
      label2: 'Good',
      value2: 36,
      percent: 70,
    },
    {
      id: 'Unit',
      label1: 'Total',
      value1: 7777,
      label2: 'Good',
      value2: 7062,
      percent: 91,
    },
    {
      id: 'Abnormality',
      label1: 'Total',
      value1: 2087,
      label2: 'Critical',
      value2: 1005,
      percent: 48,
    },
    {
      id: 'Data',
      label1: 'Not Update',
      value1: 7772,
      label2: 'Update',
      value2: 5,
      percent: 0,
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Site Code',
        columns: [
          {
            Header: 'Site Description',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.siteCode}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.siteDesc}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Total Unit',
        columns: [
          {
            Header: 'Total Modal',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.totalUnit}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.totalModal}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Good Condition',
        columns: [
          {
            Header: 'Abnormal',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.goodCondition}
                  </div>

                  <div
                    className={classNames(styles['lower-value'], {
                      [styles.abnormal]: row.original.status === 0,
                      [styles.good]: row.original.status === 1,
                    })}>
                    {row.original.abnormal}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Data Update',
        columns: [
          {
            Header: 'Data No Update',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.dataUpdate}
                  </div>
                  <div
                    className={classNames(styles['lower-value'], {
                      [styles.abnormal]: row.original.status === 0,
                      [styles.good]: row.original.status === 1,
                    })}>
                    {row.original.dataNoUpdate}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Status',
        columns: [
          {
            Header: ' ',
            Cell: params => {
              const {row} = params;

              return (
                <div
                  className={classNames(styles['upper-value'], styles.status)}>
                  <span
                    className={classNames(styles.bullet, {
                      [styles.abnormal]: row.original.status === 0,
                      [styles.good]: row.original.status === 1,
                    })}
                  />
                  {row.original.status === 0 ? 'Abnormal' : 'Good'}
                </div>
              );
            },
          },
        ],
      },
    ],
    []
  );

  /**
   * 0 : Abnormal
   * 1: Good
   */
  const columns2 = React.useMemo(
    () => [
      {
        Header: 'Site Code',
        columns: [
          {
            Header: 'Site Description',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.siteCode}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.siteDesc}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Unit Code',
        columns: [
          {
            Header: 'Unit Model',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.unitCode}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.unitModel}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Health Score',
        columns: [
          {
            Header: 'Change',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.healthScore}
                  </div>
                  <div
                    className={classNames(styles['lower-value'], {
                      [styles.minus]: row.original.change < 0,
                      [styles.plus]: row.original.change > 0,
                    })}>
                    {row.original.change}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'RUL',
        columns: [
          {
            Header: 'Lifetime',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.rul}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.lifetime}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Abnormality',
        columns: [
          {
            Header: 'Last Update',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.abnormality}
                  </div>
                  <div
                    className={classNames(styles['lower-value'], {
                      [styles.abnormal]: row.original.status === 0,
                      [styles.good]: row.original.status === 1,
                    })}>
                    {row.original.lastUpdate}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Status',
        columns: [
          {
            Header: ' ',
            Cell: params => {
              const {row} = params;

              return (
                <div
                  className={classNames(styles['upper-value'], styles.status)}>
                  <span
                    className={classNames(styles.bullet, {
                      [styles.abnormal]: row.original.status === 0,
                      [styles.good]: row.original.status === 1,
                    })}
                  />
                  {row.original.status === 0 ? 'Abnormal' : 'Good'}
                </div>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const columnsAbnormality = React.useMemo(
    () => [
      {
        Header: 'Parameter',
        columns: [
          {
            Header: 'Component',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.parameter}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.component}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Value',
        columns: [
          {
            Header: 'UOM',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.value}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.uom}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Aging',
        columns: [
          {
            Header: 'Caution/Critical',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.aging}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.caution ? 'Caution' : 'Critical'}
                  </div>
                </span>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const columnsOilAnalysis = React.useMemo(
    () => [
      {
        Header: 'Parameter',
        columns: [
          {
            Header: 'Component',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.parameter}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.component}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Value',
        columns: [
          {
            Header: 'Sample Date',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.value}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.sampleDate}
                  </div>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Oil Change',
        columns: [
          {
            Header: 'Caution/Critical',
            Cell: params => {
              const {row} = params;

              return (
                <span>
                  <div className={styles['upper-value']}>
                    {row.original.oilChange ? 'Yes' : 'No'}
                  </div>
                  <div className={styles['lower-value']}>
                    {row.original.caution ? 'Caution' : 'Critical'}
                  </div>
                </span>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const pcarDonutCenterRenderer = () => (
    <span>
      <div className={styles['chart-title']}>PCAR</div>
      <div className={styles['chart-total']}>
        {dataChartPcar.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.share,
          dataChartPcar.data[0].share
        )}
      </div>
    </span>
  );

  const msrDonutCenterRenderer = () => (
    <span>
      <div className={styles['chart-title']}>MSR</div>
      <div className={styles['chart-total']}>
        {dataChartMsr.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.share,
          dataChartMsr.data[0].share
        )}
      </div>
    </span>
  );

  const buttonFilterOptions = [
    {label: '1M', value: 30},
    {label: '3M', value: 90},
    {label: '6M', value: 180},
    {label: '1Y', value: 360},
  ];

  return (
    <Container className={styles['dashboard-container']} maxWidth={false}>
      <Breadcrumbs />

      <div>
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          className={styles['row-1']}>
          <Grid item className={styles['search-wrapper']}>
            <SearchField
              placeholder="Search Customer"
              className={styles['search-field']}
            />

            <SearchField
              placeholder="Search Model"
              className={styles['search-field']}
            />
          </Grid>

          <Grid item className={styles['status-wrapper']}>
            <Typography className={styles['last-update__label']}>
              Last Update
            </Typography>
            <Typography className={styles['last-update__date']}>
              9 April 2021, 02:15 PM
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={styles['row-2']}>
          {header.map(h => (
            <Grid item xs={3} key={h.id}>
              <Card className={styles['header-card']}>
                <CardContent className={styles['header-card__content']}>
                  <Grid item xs={4}>
                    <Typography className={styles['header-card__title']}>
                      {h.id}
                    </Typography>
                    <div className={styles['header-card__percentage']}>
                      {h.percent}
                      <span className={styles['percentage-symbol']}>%</span>
                    </div>
                  </Grid>

                  <Divider orientation="vertical" variant="middle" flexItem />

                  <Grid item xs={4}>
                    <Typography className={styles['header-card__value']}>
                      {h.value1}
                    </Typography>
                    <Typography className={styles['header-card__label']}>
                      {h.label1}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className={styles['header-card__value']}>
                      {h.value2}
                    </Typography>
                    <Typography className={styles['header-card__label']}>
                      {h.label2}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Divider variant="fullWidth" className={styles.divider} />

        <Grid
          container
          spacing={3}
          className={styles['table-chart__container']}>
          <Grid item xs={6}>
            <SearchField
              placeholder="Search Site"
              className={classNames(styles['search-field'], styles.margin)}
            />

            <DashboardTable data={dataSite.data} columns={columns} />

            <Grid container wrap="nowrap" className={styles['chart-container']}>
              <Grid item>
                <Chart
                  donutCenterRender={pcarDonutCenterRenderer}
                  style={{height: 250}}>
                  <ChartSeries>
                    <ChartSeriesItem
                      colorField="valueColor"
                      type="donut"
                      data={dataChartPcar.data}
                      categoryField="kind"
                      holeSize={60}
                      field="share">
                      <ChartSeriesLabels visible={false} />
                    </ChartSeriesItem>
                  </ChartSeries>

                  <ChartLegend visible={false} />
                </Chart>

                <Grid container spacing={2}>
                  {dataChartPcar.data.map((dp, index) => (
                    <Grid item xs={6} key={index}>
                      <div className={styles['chart-status-label']}>
                        {dp.kind}
                      </div>

                      <div className={styles['chart-status-value']}>
                        {dp.share}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Grid item>
                <Chart
                  donutCenterRender={msrDonutCenterRenderer}
                  style={{height: 250}}>
                  <ChartSeries>
                    <ChartSeriesItem
                      colorField="valueColor"
                      type="donut"
                      data={dataChartMsr.data}
                      categoryField="kind"
                      holeSize={60}
                      field="share">
                      <ChartSeriesLabels visible={false} />
                    </ChartSeriesItem>
                  </ChartSeries>

                  <ChartLegend visible={false} />
                </Chart>

                <Grid container spacing={2}>
                  {dataChartMsr.data.map((dp, index) => (
                    <Grid item xs={6} key={index}>
                      <div className={styles['chart-status-label']}>
                        {dp.kind}
                      </div>

                      <div className={styles['chart-status-value']}>
                        {dp.share}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <SearchField
              placeholder="Search Unit"
              className={classNames(styles['search-field'], styles.margin)}
            />

            <DashboardTable data={dataUnit.data} columns={columns2} />
          </Grid>
        </Grid>

        <Divider variant="fullWidth" className={styles.divider} />

        <Grid container spacing={2} className={styles['title-container']}>
          <Grid item>
            <Typography className={styles.title}>SRK</Typography>
            <Typography className={styles['sub-title']}>Soroako</Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.title}>HT2133</Typography>
            <div className={styles['sub-title-group']}>
              <span
                className={classNames(styles['sub-title-bullet'], styles.good)}
              />
              <Typography className={styles['sub-title']}>Good</Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={styles['health-score__container']}>
              <Typography className={styles['title-section']}>
                E/G Health Score - Trend
              </Typography>

              <div className={styles['button-filter__container']}>
                {buttonFilterOptions.map(option => (
                  <Button
                    key={option.value}
                    classes={{
                      root: classNames(styles['button-filter'], {
                        [styles.active]: activeFilter === option.value,
                      }),
                      label: styles['button-label'],
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => setActiveFilter(option.value)}>
                    {option.label}
                  </Button>
                ))}
              </div>

              <Chart renderAs="canvas">
                {/* <ChartTitle text={trendFilter.name} /> */}

                {/* <ChartTooltip render={_renderTooltip} /> */}

                <ChartSeries>
                  <ChartSeriesItem
                    type="line"
                    data={dataChartHealth.data.map(trend => ({
                      ...trend,
                      valueDate: new Date(trend.valueDate),
                    }))}
                    field="values"
                    categoryField="valueDate"
                    // name={trendFilter.name}
                    // markers={{
                    //   visible: (data.dataTrend || []).length === 1,
                    // }}
                  />
                </ChartSeries>

                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{rotation: 'auto', position: 'start'}}
                    maxDivisions={15}
                    minorGridLines={{visible: true}}
                    majorGridLines={{visible: true}}
                    name="categoryAxis"
                    baseUnit="days"
                  />
                </ChartCategoryAxis>

                <ChartValueAxis>
                  <ChartValueAxisItem name="valueAxis" />
                </ChartValueAxis>

                <ChartLegend visible={false} />
              </Chart>
            </div>

            <div className={styles.overview__container}>
              <Typography className={styles['title-section']}>
                Overview
              </Typography>

              <table className={styles['table-overview']}>
                <tbody>
                  <tr className={styles['head-overview']}>
                    <td>Serial Number</td>
                    <td>E/G Health Score</td>
                    <td>Last Calculation</td>
                  </tr>

                  <tr className={styles['value-overview__container']}>
                    <td className={styles['value-overview']}>123</td>
                    <td className={styles['value-overview']}>
                      50.80% (+9.39%)
                    </td>
                    <td className={styles['value-overview']}>28 March 2021</td>
                  </tr>

                  <tr className={styles['head-overview']}>
                    <td>Model</td>
                    <td>E/G RUL</td>
                    <td>VHMS Data</td>
                  </tr>

                  <tr className={styles['value-overview__container']}>
                    <td className={styles['value-overview']}>HD785-7</td>
                    <td className={styles['value-overview']}>18,000</td>
                    <td className={styles['value-overview']}>28 March 2021</td>
                  </tr>

                  <tr className={styles['head-overview']}>
                    <td>SMR</td>
                    <td>E/G RUL</td>
                    <td>PAP Data</td>
                  </tr>

                  <tr className={styles['value-overview__container']}>
                    <td className={styles['value-overview']}>54,146,40H</td>
                    <td className={styles['value-overview']}>9623.10H</td>
                    <td className={styles['value-overview']}>17 March 2021</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <Typography className={styles['title-section']}>
                Parameter
              </Typography>
              <Chart renderAs="canvas">
                {/* <ChartTitle text={trendFilter.name} /> */}

                {/* <ChartTooltip render={_renderTooltip} /> */}

                <ChartSeries>
                  <ChartSeriesItem
                    type="line"
                    data={dataChartHealth.data.map(trend => ({
                      ...trend,
                      valueDate: new Date(trend.valueDate),
                    }))}
                    field="values"
                    categoryField="valueDate"
                    // name={trendFilter.name}
                    // markers={{
                    //   visible: (data.dataTrend || []).length === 1,
                    // }}
                  />
                </ChartSeries>

                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{rotation: 'auto', position: 'start'}}
                    maxDivisions={15}
                    minorGridLines={{visible: true}}
                    majorGridLines={{visible: true}}
                    name="categoryAxis"
                    baseUnit="days"
                  />
                </ChartCategoryAxis>

                <ChartValueAxis>
                  <ChartValueAxisItem name="valueAxis" />
                </ChartValueAxis>

                <ChartLegend visible={false} />
              </Chart>
            </div>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography className={styles['title-section']}>
                  Abnormality
                </Typography>

                <DashboardTable
                  data={dataAbnormality.data}
                  columns={columnsAbnormality}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={styles['title-section']}>
                  Oil Analysis
                </Typography>

                <DashboardTable
                  data={dataOilAnalysis.data}
                  columns={columnsOilAnalysis}
                />
              </Grid>
            </Grid>

            <div>
              <Typography className={styles['title-section']}>
                Payload
              </Typography>
              <Chart renderAs="canvas">
                {/* <ChartTitle text={trendFilter.name} /> */}

                {/* <ChartTooltip render={_renderTooltip} /> */}

                <ChartSeries>
                  <ChartSeriesItem
                    type="bar"
                    data={dataChartHealth.data.map(trend => ({
                      ...trend,
                      valueDate: new Date(trend.valueDate),
                    }))}
                    field="values"
                    categoryField="valueDate"
                    // name={trendFilter.name}
                    // markers={{
                    //   visible: (data.dataTrend || []).length === 1,
                    // }}
                  />
                </ChartSeries>

                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{rotation: 'auto', position: 'start'}}
                    maxDivisions={15}
                    minorGridLines={{visible: true}}
                    majorGridLines={{visible: true}}
                    name="categoryAxis"
                    baseUnit="days"
                  />
                </ChartCategoryAxis>

                <ChartValueAxis>
                  <ChartValueAxisItem name="valueAxis" />
                </ChartValueAxis>

                <ChartLegend visible={false} />
              </Chart>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
