import React from 'react';
import Dashboard from 'components/Dashboard';
import {StylesProvider} from '@material-ui/styles';
import classes from './App.module.css';
import '@progress/kendo-theme-material/dist/all.css';
import '@fontsource/roboto';
import 'hammerjs';

const App = () => (
  <div>
    <StylesProvider injectFirst>
      <Dashboard />
    </StylesProvider>
  </div>
);

export default App;
